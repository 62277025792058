export function formatDate(input: string): string {
  const date = new Date(input)
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })
}

export function absoluteUrl(input: string) {
  return `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${input}`
}

export function frontendUrl(input: string): string {
  const formattedInput = input.startsWith('/') ? input : `/${input}`;
  return `${process.env.NEXT_PUBLIC_BASE_URL}${formattedInput}`;
}