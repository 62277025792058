import { useContext } from 'react';

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { DarkModeContext } from '@contexts/darkMode';

import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { useTranslation } from 'next-i18next';

import styles from './SwitchDarkMode.module.scss';

export const MaterialUISwitch = styled(Switch)(() => ({
  width: 52,
  height: 28,
  padding: 6,
  '& .MuiSwitch-switchBase': {
    margin: 0,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url(/assets/images/Leaf.svg)`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 26,
    height: 26,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '18px',
      backgroundImage: `url(/assets/images/LightbulbFilament.svg)`,
    },
  },
}));

interface SwitchDarkModeProps {
  layout?: "empty" | "standard";
}

export default function SwitchDarkMode({layout }: SwitchDarkModeProps) {
  const { t } = useTranslation();
  const { darkMode, setDarkMode } = useContext(DarkModeContext);
  const handleChangeSwitch = (event) => {
    setDarkMode(event.target.checked)
  };

  return (
    <FormControlLabel
      className={styles.switchDarkMode}
      label={<Typography variant="description">{layout == "empty" ? t('layout.empty.darkMode') : t('header.darkMode')}</Typography>}
      labelPlacement={layout == "empty" ? "top" : "start"}
      control={<MaterialUISwitch onChange={handleChangeSwitch} checked={darkMode} />}
    />
  )
}