import axios from "axios";
import { frontendUrl } from '@lib/utils';

export function setFormData(item): void {
  let target: string;
  let interestArea: string;
  let servizi: string;

  if (item?.field_form_target) {
    window.localStorage.removeItem("formdata");

    target = item?.field_form_target && item.field_form_target;
    interestArea =
      item?.field_form_interest_area && item.field_form_interest_area;
    servizi = item?.field_form_servizi && item.field_form_servizi;

    window.localStorage.setItem(
      "formdata",
      JSON.stringify({ target, interestArea, servizi })
    );
  }
}

export function getFormData() {
  return JSON.parse(localStorage.getItem("formdata"));
}

export function setFieldsDefaultValue(schema) {
  const formData = getFormData();

  const newSchema = schema.map((step) => {
    const areaInteresse = step.fields.find(
      (field) => field.name === "area_di_interesse_principale"
    );
    if (areaInteresse && formData.interestArea)
      areaInteresse.initialValue = formData.interestArea;

    const servizi = step.fields.find(
      (field) =>
        field.name === "scegli_il_servizio___tendina" ||
        field.name === "scegli_il_servizio"
    );
    if (servizi && formData.servizi) servizi.initialValue = formData.servizi;

    return step;
  });

  return newSchema;
}

const getCookieValue = (name) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

async function uploadFileToFolder(file, folderPath) {
  const formData = new FormData();
  formData.append('file', file.inputFiles[0]);
  formData.append('filePath', file.inputValue);
  formData.append('folderPath', folderPath);

  try {
    const response = await axios.post('/api/hubspot/uploadFile', formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}

export async function sendFormData(formId, formData, router) {
  const hubspotutk: string = getCookieValue("hubspotutk") || null;
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/26099279/${formId}`;

  const parseValues = (value) => {
    if (Array.isArray(value)) {
      return value.join(";");
    } else {
      return value;
    }
  };

  const fields = await Promise.all(Object.keys(formData).map(async (value) => {
    if (formData[value].hasOwnProperty("inputFiles")) {
      const file = formData[value];
      const folderPath = value;

      const url = await uploadFileToFolder(file, folderPath);
      
      return {
        objectTypeId: "0-1",
        name: value,
        value: parseValues(url)
      };

    }
    if (!formData[value].hasOwnProperty("inputFiles")) {
      return {
        objectTypeId: "0-1",
        name: value,
        value: parseValues(formData[value]),
      };
    }
  }));

  const final_data = JSON.stringify({
    submittedAt: Date.now(),
    fields: fields,
    context: {
      hutk: hubspotutk,
      pageUri: router.asPath,
      pageName: frontendUrl(router.asPath),
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: "I agree to allow Example Company to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: "I agree to receive marketing communications from Example Company.",
          },
        ],
      },
    },
  });

  try {
    const response = await axios.post(url, final_data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}