import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

export const Loader:React.FC<{active: boolean}> = ({active}) => {
  
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={active} >
      <CircularProgress color="inherit"/>
    </Backdrop>
  )
}

export default Loader